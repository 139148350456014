.remove_room {
  font-size: 10px !important;
}
@media screen and (max-width: 1300) {
  .remove_room {
    font-size: 3px !important;
    border: 2px solid red;
  }
}
/* @media screen and (min-width: 870px) and (max-width: 1039px) {
  .table_container {
    border: 1px solid blue;
    max-width: 600px;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1079px) {
  .table_container {
    border: 1px solid pink;
    max-width: 760px;
  }
}
@media screen and (min-width: 800px) and (max-width: 869px) {
  .table_container {
    border: 1px solid green;
    max-width: 530px;
  }
} */
