.inputField {
    background-color: none;
    color: red;
    height: 40px;
    /* width: 200px; */
    border: none;
}

.inputField:focus {
    outline: none;
}

.cart_con {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 80px;
}

@media (max-width:1000px) {
    .cart_con {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width:100px) {
    .cart_con {
        grid-template-columns: 1fr;
    }
}