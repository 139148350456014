.main_container {
    width: 100%;
    height: 90vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pin_main_con {
    background-color: #eff6ff;
    height: 100vh;
    /* display: flex;
    align-items: center; */
}

.pin_main_container {
    width: 400px !important;
    height: 600px;
    background-color: #FFFFFF;
    border-radius: 20px;
}



.name_container {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
}

.pin_container {
    /* border: 2px solid green; */
    width: 100%;
    height: 85%;

}

.num_container {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 1rem
}

.single_num_con {
    display: flex;
    justify-content: center;

    /* border: 2px solid red; */
}

.num {
    width: 65px;
    height: 65px;
    color: #000000;
    font-weight: 100 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a0a0a0;
    border-radius: 50%;
    padding: 15px;
    font-size: 25px;
}

.num_next {
    background-color: #a8cfff;
}

.num_backSpace {
    background-color: #ffbf008b;
    font-weight: 900 !important;

}

.input_con {
    padding: 0% 20%;
    margin: 0px 0px 40px 0px;
}

.input_field {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    text-align: center !important;
    letter-spacing: 25px;
    font-size: 40px;
    -webkit-text-security: disc;
}

.input_field:focus {
    outline: none;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (max-width:500px) {

    .pin_main_container {
        width: 100% !important;
        margin: 0% 7%;
    }

    .num_container {
        row-gap: 1rem;
        column-gap: 0.5rem
    }


}

@media (max-width:400px) {
    .num_container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .num {
        width: 55px;
        height: 55px;
        padding: 15px;
        font-size: 20px;
    }

}