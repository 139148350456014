.top_bar_btn_active {
  background-color: #65beff;
}

.top_bar_btn {
  background-color: rgb(174, 173, 173);
}

.bgGrey {
  background-color: rgb(174, 173, 173);
}

.current_active {
  color: skyblue;
  border-bottom: 1px solid skyblue;
  transition: all 0.1s linear;
}

.active_color {
  background-color: #65beff;
}

.over_due_color {
  background-color: orange !important;
}

.filled_color {
  background-color: green;
}

.textArea {
  /* resize: none; */
  /* overflow: hidden;
    min-height: 50px; */
  /* max-height: 100px; */
}

.startEnd_date {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-left: 6%;
}

.start_date1 {
  font-size: 14px;
}

.end_date1 {
  font-size: 14px;
}

.status_class {
  width: 7% !important;
  font-size: 13px;
}

.month1 {
  width: 24% !important;
  text-align: center !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
}

.month2 {
  width: 24% !important;
  text-align: center !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
}

.month3 {
  width: 24% !important;
  text-align: center !important;
  padding-top: 0.5% !important;
  padding-bottom: 0.5% !important;
}

.over_due {
  width: 20% !important;
  font-size: 13px;
}

.text_current_past {
  width: 55%;
  padding-left: 5%;
  display: flex;
  justify-content: space-between;
}

.account_Icon {
  padding-left: 5%;
}

.arrow_icon {
  padding-left: 5%;
}

/* .date_status{
    display: flex;
    justify-content: space-between;
 
  } */
.dates {
  margin-left: 25px;
}

@media screen and (max-width: 1300px) {
  .status_class {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1250px) {
  .status_class {
    margin-right: 6px;
  }

  .startEnd_date {
    padding-left: 7%;
  }

  .end_date1 {
    margin-left: px;
  }

  .startEnd_date {
    width: 54% !important;
  }
}

@media screen and (max-width: 1200px) {
  /* .date_status{
    display: flex;
    justify-content: space-between;
   
  
  } */
}

@media screen and (max-width: 1150px) {
  .arrow_icon {
    padding-left: 6%;
    /* border:2px solid red */
  }

  .account_Icon {
    padding-left: 6%;
  }

  .text_current_past {
    padding-left: 6%;
  }

  .month1 {
    width: 20% !important;
  }

  .month2 {
    width: 20% !important;
  }

  .month3 {
    width: 20% !important;
  }

  .over_due {
    width: 30% !important;
  }
}

@media screen and (max-width: 1100px) {
  .status_class {
    margin-right: 8px;
  }
}

@media screen and (max-width: 1050px) {
  .month1 {
    margin-left: 10px;
  }

  .startEnd_date {
    padding-left: 8%;
  }
}

@media screen and (max-width: 950px) {
  .over_due {
    width: 28% !important;
    font-size: 10px !important;
  }

  .status_class {
    font-size: 10px;
  }
}

@media screen and (max-width: 850px) {
  .text_current_past {
    display: flex;
    justify-content: space-between;
    width: 65%;
    padding-left: 10%;
  }

  .arrow_icon {
    padding-left: 10%;
    /* border:2px solid red */
  }

  .account_Icon {
    padding-left: 10%;
  }

  .date_status {
    display: flex;
    justify-content: space-between;
  }

  .statuses {
    margin-right: 15px;
  }
}

@media screen and (max-width:550px) {
  .status_class {
    margin-right: 15px;
  }

  /* .month1 {
    margin-left: 10px;
  } */
}

@media screen and (max-width:450px) {
  .month1 {
    width: 25% !important;
  }

  .month2 {
    width: 25% !important;
  }

  .month3 {
    width: 25% !important;
  }
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}