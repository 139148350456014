@media screen and (max-width:899px) {
    .dob_edit{
      width: 230px !important;
    }
     .gender_contain{
        width:230px;
    }
}

/* @media screen and (max-width:475) {
    .dob_edit{
      min-width: 0 !important;
    }
   
} */