/* .gender_container{
    width:730px;
}
@media screen and (max-width:1400) {
     .gender_container{
    width:260px;
   }
} */
@media screen and (max-width:899px) {
   .dob_input{
    width: 230px !important;
   }
   .gender_container{
    width:230px;
   }
}