@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600);

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

a {
  text-decoration: none;
  color: inherit;
}

.btn_primary {
  background-color: #1799cd !important;
  color: white !important;
}

img {
  width: 100%;
  height: auto;
}

.add_btn {
  color: white !important;
  text-transform: capitalize !important;
  background-color: #64b92a !important;
  padding: 6px 12px !important;
  box-shadow: 2px 2px 3px rgb(128, 127, 127);
}

.add_btn:hover {
  background-color: #45841b !important;
}

.cancel_btn {
  color: black !important;
  padding: 6px 12px !important;
  text-transform: capitalize !important;
  box-shadow: 2px 2px 3px rgb(128, 127, 127);
}

.cancel_btn:hover {
  background-color: rgb(236, 230, 230) !important;
}

.nav_item {
  cursor: pointer;
}

.search_input {
  border-radius: 10px;
  border: 1px solid black;
  padding: 0px 10px;
  width: 100%;
  height: 100%;
}

.date_btn {
  height: 40px;
  border-radius: 7px;
  border: none;
  padding: 0px 5%;
  max-width: 130px;
}

.dd {
  background-color: #ffeed8;
  color: #fa981d;
  padding: 1% 3%;
  border-radius: 6px;
  margin: 10px 15px !important;
  font-size: 17px !important;
  cursor: pointer;
  width: fit-content;
  position: relative;
}

.cross {
  position: absolute;
  top: -12px;
  right: -3px;
  color: red;
}

.activeRecord {
  color: white;
  background-color: #fa981d;
}

.recordCon {
  min-height: 500px;
}

.subRecord {
  background-color: #e7ffc5;
  color: #456c0f;
  padding: 1% 3%;
  border-radius: 6px;
  margin: 10px 15px !important;
  cursor: pointer;
  font-size: 17px !important;
}

.subActivity {
  background-color: #fff1f6;
  color: #991b1b;
  padding: 1% 3%;
  border-radius: 6px;
  margin: 10px 15px !important;
  font-size: 15px !important;
  cursor: pointer;
}

.active {
  color: #fa981d;
  border-bottom: 1px solid #fa981d;
}

.active1 {
  color: #3faeed;
  border-bottom: 1px solid #3faeed;
}

.greenBtn {
  width: 108.59px;
  height: 31.64px;
  background-color: #83b441;
  box-shadow: 4px 4px 30px rgba(169, 255, 129, 0.2);
  border-radius: 4px;
}

.btn_secondary {
  background: #83b441 !important;
  box-shadow: 4px 4px 30px rgba(169, 255, 129, 0.2) !important;
  border-radius: 4px !important;
  color: white !important;
}

.pointer {
  cursor: pointer !important;
  /* color: #ecb776; */
}

.recordHeading {
  background-color: #ffeed8;
  color: #fa981d;
  padding: 1% 3%;
  border-radius: 6px;
  margin: 10px 15px !important;
  font-size: 17px !important;
  cursor: pointer;
  width: fit-content;
}

/* scrol bar design */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
}

/* admin configuration page  */

.configuration_topCon {
  width: 600px !important;
}

@media (max-width: 1000px) {
  .configuration_topCon {
    width: 100% !important;
  }
}

/* .config_itemCon {
  display: flex;
  align-items: center;
}

@media (max-width:800px) {
  .config_itemCon {
    flex-direction: column !important;
    align-items: flex-start;
  }
} */

.addRecord_table_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}

@media (max-width: 800px) {
  .addRecord_table_grid {
    display: block;
  }
}

.duplicateClass {
  border: 2px solid red;
  box-sizing: border-box;
}

.searchInput {
  width: 350px !important;
}

@media (max-width: 850px) {
  .searchInput {
    width: 100% !important;
    margin-right: 30px !important;
  }
}

.text-gray {
  color: gray;
}

#tableReport .table tr th {
  padding: 25px 48px !important;
}

#tableReport .table tr td {
  padding: 15px 48px !important;
}

.cap {
  text-transform: capitalize !important;
}

.fs-18 {
  font-size: 18px !important;
}
@media (max-width: 900px) {
  .assessment_box {
    gap: 30px;
  }
  .grid_container {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
  }
}
